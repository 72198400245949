<template>
  <div
    class="relative min-h-[3rem] flex"
    :class="{
      'inline-flex min-w-[300px]': !block,
      disabled,
      'mb-3': actualErrors.length > 0,
    }"
  >
    <input
      ref="input"
      class="absolute py-2 outline-none inset-0 block bg-neutral-100 dark:bg-neutral-900 input-border input-text"
      :class="{
        error: actualErrors.length > 0,
        'block w-full h-full': block,
        'px-2 rounded-md': !pill,
        'px-4 rounded-full': pill,
        'pr-12': showSubmit,
      }"
      :value="modelValue"
      :placeholder="placeholder"
      :type="type"
      :step="step"
      @focus="onFocus"
      @blur="onBlur"
      @input="(e) => updateInput(e.target as HTMLInputElement)"
    />
    <label
      v-if="label"
      class="absolute pointer-events-none transition-all rounded input-label-text max-w-[calc(100%-2rem)]"
      :class="{
        'top-3': inputState === 'unfocused.empty',
        '-top-2 text-xs px-1 -mx-1': inputState !== 'unfocused.empty',
        'left-4': inputState === 'unfocused.empty' && !pill,
        'left-6': inputState === 'unfocused.empty' && pill,
        'left-3': inputState !== 'unfocused.empty' && !pill,
        'left-5': inputState !== 'unfocused.empty' && pill,
        error: actualErrors.length > 0,
      }"
    >
      <span class="relative z-[2] truncate">
        {{ label }}
      </span>
      <div
        class="absolute left-0 bottom-0 w-full h-1/2 z-[1] bg-neutral-100 dark:bg-neutral-900"
      ></div>
    </label>
    <p
      v-if="actualErrors.length > 0"
      class="absolute top-12 text-red-600 text-sm"
    >
      {{ actualErrors.join(" ") }}
    </p>
    <div class="absolute right-4 top-[calc(0.5rem+1px)]">
      <NamiButton
        v-if="showSubmit && modelValue && modelValue.toString().length > 0"
        buttonType="secondary"
        :icon="IconArrowRight"
        small
        pill
        block
      />
    </div>
  </div>
</template>

<script setup lang="ts" generic="T extends string | number">
import { IconArrowRight } from "@tabler/icons-vue";
import type { Ref } from "vue";
import type { InputReplacer, InputRule } from "~/types/validation";

interface Props {
  modelValue: T;
  label?: string;
  placeholder?: string;
  block?: boolean;
  pill?: boolean | string;
  showSubmit?: boolean;
  disabled?: boolean;
  rules?: InputRule<T>[];
  replacers?: InputReplacer<T>[];
  type?: "text" | "number";
  step?: string | number;
  focusOnMount?: boolean;
}

interface Exposes {
  focus: () => void;
  errors: Ref<string[]>;
  validate: (val: T) => void;
  element: Ref<HTMLInputElement | undefined>;
}

const props = defineProps<Props>();
const emit = defineEmits<{
  (e: "update:modelValue", v: T): void;
  (e: "focus"): void;
  (e: "blur"): void;
  (e: "submit"): void;
}>();

const finalReplacers = computed(() => {
  return props.replacers ?? [];
});

const input = ref<HTMLInputElement>();
const inputState = ref<"unfocused.empty" | "unfocused.populated" | "focused">(
  props.modelValue.toString() !== ""
    ? "unfocused.populated"
    : "unfocused.empty",
);
watch(
  () => props.modelValue,
  (v) => {
    inputState.value = v || v === 0 ? "unfocused.populated" : "unfocused.empty";
  },
);

function handleHotkeys(e: KeyboardEvent) {
  switch (e.code) {
    case "Escape": {
      input.value?.blur();
      onBlur();
      break;
    }

    case "Enter": {
      input.value?.blur();
      onBlur();
      emit("submit");
    }
  }
}

const errors = ref<(string | null)[]>([]);
const actualErrors = computed(
  () => errors.value.filter((error) => error !== null) as string[],
);

function updateInput(element: HTMLInputElement) {
  let finalVal = element.value as T;
  finalReplacers.value?.forEach((replacer) => {
    finalVal = replacer(finalVal);
  });

  const val = props.type === "number" ? Number(finalVal) : finalVal;

  emit("update:modelValue", val as T);
  validate(val as T);
}

function onFocus() {
  inputState.value = "focused";
  emit("focus");
  document.addEventListener("keydown", handleHotkeys);
}

function onBlur() {
  inputState.value =
    props.modelValue && props.modelValue.toString().length > 0
      ? "unfocused.populated"
      : "unfocused.empty";
  emit("blur");
  document.removeEventListener("keypress", handleHotkeys);
  validate();
}

function validate(val?: T) {
  errors.value =
    props.rules?.map((rule) => rule(val ?? props.modelValue)) ?? [];
}

defineExpose<Exposes>({
  focus: () => input.value?.focus(),
  errors: actualErrors,
  validate: validate,
  element: input,
});

onMounted(() => {
  if (props.focusOnMount) {
    input.value?.focus();
  }
});
</script>

<style>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

input[type="number"]:focus {
  box-shadow: none;
}
</style>
